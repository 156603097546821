import {useEffect} from 'react';
import {I18n} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {MenuNames} from '../../core/enums/menu-names';
import {WcsLabelFactory} from '../../infrastructure/i18n/repositories/wcs-label-factory';
import {ApisInitialState} from '../../infrastructure/redux/apis-initial-state';
import {RootState} from '../../infrastructure/redux/store';
import {Footer} from '../components/footer';
import Loader from '../components/loader';
import {Menu} from '../components/menu';
import {FloatButton} from '../components/whatsapp-button';
import {AboutCity} from '../pages/about-city';
import {Contact} from '../pages/contact';
import {GalleryPage} from '../pages/gallery';
import {Home} from '../pages/home';
import {Services} from '../pages/services';
import {Tourism} from '../pages/tourism';

export const Layout = () => {
  const dispatch = useDispatch();

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  useEffect(() => {
    ApisInitialState(locale, dispatch);

    window.history.scrollRestoration = 'manual';
  }, [locale]);

  return (
    <I18n locale={locale} messages={WcsLabelFactory.make(locale)}>
      <Router>
        <Menu />

        <FloatButton />

        <Loader>
          <Switch>
            <Route path="/" exact render={() => <Home />} />

            <Route
              path={`/${MenuNames.SERVICES}`}
              exact
              render={() => <Services />}
            />

            <Route
              path={`/${MenuNames.GALLERY}`}
              exact
              render={() => <GalleryPage />}
            />

            <Route
              path={`/${MenuNames.TOURISM}`}
              exact
              render={() => <Tourism />}
            />

            <Route
              path={`/${MenuNames.ABOUT_CITY}`}
              exact
              render={() => <AboutCity />}
            />

            <Route
              path={`/${MenuNames.CONTACT}`}
              exact
              render={() => <Contact />}
            />

            <Redirect from="*" to="/" />
          </Switch>
        </Loader>

        <Footer />
      </Router>
    </I18n>
  );
};

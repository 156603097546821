import styled from 'styled-components';

import {fonts} from '../../styles/global';

export const Home = styled.div`
  font-family: ${fonts.verdana}, sans-serif;
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  padding-bottom: 100px;
`;

export const BookingButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 30px 0 0 0;
`;

import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  gap: 8px;
  min-width: 120px;
  height: 36px;
  border-radius: 4px;
  color: ${colors.white};
  background: ${colors.green};
  font-family: ${fonts.verdana};
  font-size: 16px;
  line-height: 18px;
  transition: 0.3s;

  &:hover {
    background: ${colors.yellow};
  }
`;

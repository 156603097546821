import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const NavWrapper = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(to right, #eeeeef, #ebebeb);
  border-top: 6px solid ${colors.green};
  padding: 10px;
`;

export const MenuWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1110px) {
    padding: 10px 0;
    flex-direction: column;
    gap: 10px;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .icon {
    width: 350px;

    @media (max-width: 1110px) {
      width: 275px;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 100px;

  @media (max-width: 1110px) {
    align-items: center;
    gap: 10px;
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;

  @media (max-width: 430px) {
    flex-direction: row-reverse;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .language-select {
    @media (max-width: 1110px) {
      font-size: 14px;
    }

    @media (max-width: 675px) {
      display: none;
    }
  }
`;

export const MenuHeaderPhone = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  svg {
    width: 24px;
    font-size: 24px;
    color: ${colors.yellow};

    @media (max-width: 1110px) {
      width: 18px;
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    color: ${colors.green};
    font-family: ${fonts.verdana};

    @media (max-width: 1110px) {
      font-size: 14px;
    }
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 675px) {
    display: none;
  }
`;

export const MenuItemsWrapperResponsive = styled.div`
  @media (min-width: 676px) {
    display: none;
  }
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MenuItemsResponsiveOpenIcon = styled.div`
  position: relative;
  width: 18px;
  height: 18px;

  svg {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
`;

export const MenuItemsResponsiveCloseIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-family: ${fonts.verdana};
  color: ${colors.green};

  svg {
    margin: 10px 30px 10px 0;
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
`;

export const MenuItemsResponsive = styled.div<{open: boolean}>`
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: ${props => {
    return props.open ? 0 : '-500px';
  }};
  left: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${colors.softYellow},
    ${colors.yellow}
  );
  opacity: 0.96;
  border-bottom: 6px solid ${colors.green};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: 0.5s;
  z-index: 99;
  padding-bottom: 10px;

  a {
    width: 100%;
  }

  @media (min-width: 676px) {
    display: none;
  }
`;

export const MenuItemsResponsiveContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MenuLink = styled.div<{active: boolean}>`
  cursor: pointer;

  @media (min-width: 676px) {
    border: 1px solid
      ${props => (props.active ? colors.yellow : colors.softerGrey)};
    border-radius: 5px;
    text-align: center;
    color: ${colors.green};
    font-family: ${fonts.verdana};
    padding: 0 8px;
    margin: 5px 0;
    font-size: 18px;
    transition: 0.3s;

    @media (max-width: 721px) {
      font-size: 16px;
    }

    :hover {
      color: ${colors.yellow};
    }
  }

  @media (max-width: 675px) {
    background-color: ${props => props.active && colors.green};
    color: ${props => (props.active ? colors.yellow : colors.green)};
    padding: 8px 16px;
    text-align: center;
    font-size: 24px;
    font-family: ${fonts.verdana};
  }
`;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Languages} from '../../../core/enums/languages';

export type LocaleStore = {
  currentLanguage: Languages;
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState: () => {
    const locale = Languages.PT_BR;

    return {
      currentLanguage: locale,
    };
  },
  reducers: {
    changeLanguage: (
      state: LocaleStore,
      {payload: locale}: PayloadAction<Languages>
    ) => {
      state.currentLanguage = locale;
    },
  },
});

export const {changeLanguage} = localeSlice.actions;

export const localeSliceReducer = localeSlice.reducer;

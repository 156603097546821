import {AnyAction, ThunkDispatch} from '@reduxjs/toolkit';

import {Languages} from '../../core/enums/languages';
import {getCompany} from './slices/apis/company-slice';

export const ApisInitialState = (
  locale: Languages,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  dispatch(getCompany(locale));
};

import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import {CompanyGateway} from '../../../../core/apis/api-strapi/gateways/company-gateway/company-gateway';
import {CompanyDto} from '../../../../core/apis/api-strapi/gateways/company-gateway/dtos/company-dto';
import {Languages} from '../../../../core/enums/languages';

export type CompanyStore = {
  company?: CompanyDto;
  isLoading: boolean;
};

export const getCompany = createAsyncThunk(
  'company/getCompany',
  async (currentLanguage: Languages) => {
    const company = await CompanyGateway.make(currentLanguage).find();

    return company.attributes;
  }
);

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    isLoading: false,
    company: {
      name: '',
    } as CompanyDto,
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CompanyStore>) => {
    builder.addCase(getCompany.pending, (state: CompanyStore) => {
      state.isLoading = true;
    });
    builder.addCase(getCompany.rejected, (state: CompanyStore) => {
      state.isLoading = false;
    });
    builder.addCase(getCompany.fulfilled, (state: CompanyStore, action) => {
      state.isLoading = false;
      state.company = action.payload;
    });
  },
});

export const companySliceReducer = companySlice.reducer;

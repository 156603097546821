import {useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';

import {MenuNames} from '../../../core/enums/menu-names';
import {applyBreakline} from '../../../core/services/text-paragraph';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {Title} from '../../components/title';
import {colors, images} from '../../styles/global';
import * as S from './style';

export const AboutCity = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(goToPage(MenuNames.ABOUT_CITY));
  }, []);

  return (
    <S.AboutCity>
      <S.AboutCityDescription>
        <Title
          left
          title={t('aboutCity.title')}
          labelColor={colors.green}
          dividerColor={colors.yellow}
        />

        <p>{applyBreakline(t('aboutCity.description'))}</p>
      </S.AboutCityDescription>

      <S.AboutCityCards>
        <S.AboutCityLeft>
          <S.AboutCityLeftSquare image={images.aboutCity.about2} />
          <S.AboutCityLeftSquare image={images.aboutCity.about1} />
        </S.AboutCityLeft>
        <S.AboutCityRight>
          <img
            width={704}
            height={937}
            alt="about-city-3"
            src={images.aboutCity.about3}
          />
        </S.AboutCityRight>
      </S.AboutCityCards>
    </S.AboutCity>
  );
};

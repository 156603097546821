import styled from 'styled-components';

import {colors} from '../../styles/global';

export const WppButton = styled.div`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: ${colors.white};
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;

  a {
    text-decoration: none;
    color: inherit;
  }

  svg {
    width: 30px;
    font-size: 30px;
  }
`;

import {TitleProps} from './props';
import * as S from './style';

export const Title = (props: TitleProps) => {
  return (
    <S.Title left={props.left} labelColor={props.labelColor}>
      <h1>{props.title}</h1>
      <S.TitleDivider dividerColor={props.dividerColor} />
    </S.Title>
  );
};

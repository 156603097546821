export const layers = {};

export const colors = {
  white: '#FFFFFF',
  softerGrey: '#ededed',
  softGrey: '#f3f3f3',
  grey: '#282c34',
  green: '#045902',
  darkGreen: '#023400',
  yellow: '#E4AD2D',
  softYellow: '#ECC873',
};

export const fonts = {
  cinzel: 'Cinzel',
  verdana: 'Verdana',
};

export const images = {
  menu: {
    logo: '/images/menu/logo.png',
  },
  footer: {
    logo: '/images/footer/logo.png',
  },
  home: {
    welcome: {
      banner: '/images/pages/home/welcome_banner.jpeg',
    },
    hotel: {
      left: '/images/pages/home/hotel_portrait_left.png',
      mid: '/images/pages/home/hotel_portrait_mid.png',
      right: '/images/pages/home/hotel_portrait_right.png',
    },
    city: {
      banner: '/images/pages/home/city_banner.png',
    },
  },
  services: {
    yellowPolygon: '/images/services/icon-polygon-yellow.png',
    greenPolygon: '/images/services/icon-polygon-green.png',
  },
  tourism: {
    sierra: '/images/pages/tourism/sierra.png',
    coinHouse: '/images/pages/tourism/house_coin.jpg',
    station: '/images/pages/tourism/station.jpeg',
  },
  aboutCity: {
    about1: '/images/pages/about-city/about_portrait_1.jpg',
    about2: '/images/pages/about-city/about_portrait_2.jpg',
    about3: '/images/pages/about-city/about_portrait_3.png',
  },
  gallery: {
    img7: '/images/pages/gallery/img7.jpeg',
    img8: '/images/pages/gallery/img8.jpeg',
    img10: '/images/pages/gallery/img10.jpeg',
    img12: '/images/pages/gallery/img12.jpeg',
    img14: '/images/pages/gallery/img14.jpeg',
    img15: '/images/pages/gallery/img15.jpeg',
    img18: '/images/pages/gallery/img18.jpeg',
    img19: '/images/pages/gallery/img19.jpeg',
    img21: '/images/pages/gallery/img21.jpeg',
    img26: '/images/pages/gallery/img26.jpeg',
  },
};

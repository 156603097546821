import {faLanguage} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useDispatch} from 'react-redux';
import Select, {components} from 'react-select';

import {changeLanguage} from '../../../infrastructure/redux/slices/locale-slice';
import {colors} from '../../styles/global';
import {LanguageSelectProps} from './props';
import * as S from './style';

export const LanguageSelect = (props: LanguageSelectProps) => {
  const dispatch = useDispatch();

  return (
    <S.LanguageSelect>
      <Select
        id="selectbox"
        instanceId="selectbox"
        isSearchable={false}
        value={
          props.options.filter(
            option => option.value === props.currentOption.value
          )[0]
        }
        onChange={language =>
          language && dispatch(changeLanguage(language.value))
        }
        options={props.options}
        components={{
          Option: props => (
            <S.LanguageOption>
              <components.Option {...props}>
                {props.data.label}
              </components.Option>
            </S.LanguageOption>
          ),
          SingleValue: props => (
            <S.LanguageDefaultOption>
              <FontAwesomeIcon color={colors.yellow} icon={faLanguage} />
              {props.data.label}
            </S.LanguageDefaultOption>
          ),
        }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: colors.green,
            primary25: colors.yellow,
            primary50: colors.yellow,
            primary75: colors.yellow,
            neutral0: colors.white,
            neutral5: colors.yellow,
            neutral10: colors.yellow,
            neutral20: colors.yellow,
            neutral30: colors.yellow,
            neutral40: colors.yellow,
            neutral50: colors.yellow,
            neutral60: colors.yellow,
            neutral70: colors.yellow,
            neutral80: colors.yellow,
            neutral90: colors.yellow,
            danger: colors.yellow,
            dangerLight: colors.yellow,
          },
        })}
      />
    </S.LanguageSelect>
  );
};

import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const FooterWrapper = styled.footer`
  background-image: linear-gradient(to right, #044803, ${colors.darkGreen});
  bottom: 0;
  width: 100%;
  border-top: 6px solid ${colors.yellow};
`;

export const FooterContent = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 0 25px;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    gap: 70px;
    align-items: center;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (max-width: 1024px) {
    margin: 0;
  }

  h1 {
    font-family: ${fonts.cinzel};
    font-weight: bold;
    font-size: 28px;
    color: ${colors.yellow};
  }

  img {
    width: 175px;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  @media (max-width: 1023px) {
    width: 90vw;
  }
`;

export const FooterMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const FooterMenuItem = styled.div<{active: boolean}>`
  color: ${props => (props.active ? colors.yellow : colors.softGrey)};
  font-size: 16px;
  font-family: ${fonts.verdana};
  transition: 0.8s;
  cursor: pointer;

  :hover {
    color: ${colors.yellow};
  }
`;

export const FooterContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 1023px) {
    gap: 70px;
  }
`;

export const FooterSectionIconLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-family: ${fonts.verdana};
  color: ${colors.softGrey};

  svg {
    width: 20px;
    font-size: 20px;
    color: ${colors.yellow};
  }
`;

export const FooterSectionSocialMedias = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  .facebook:hover {
    color: #4267b2;
  }

  .instagram:hover {
    color: #8a3ab9;
  }

  svg {
    transition: 0.5s;
    width: 30px;
    font-size: 30px;
    color: ${colors.yellow};
  }
`;

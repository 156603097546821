import {
  faBath,
  faCar,
  faConciergeBell,
  faGamepad,
  faHouseUser,
  faUtensils,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';

import {MenuNames} from '../../../core/enums/menu-names';
import {applyBreakline} from '../../../core/services/text-paragraph';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {Title} from '../../components/title';
import {colors} from '../../styles/global';
import * as S from './style';

export const Services = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(goToPage(MenuNames.SERVICES));
  }, []);

  const servicesIcon = [
    {
      desc: t('services.items.home'),
      icon: faHouseUser,
    },
    {
      desc: t('services.items.bath'),
      icon: faBath,
    },
    {
      desc: t('services.items.wifi'),
      icon: faWifi,
    },
    {
      desc: t('services.items.car'),
      icon: faCar,
    },
    {
      desc: t('services.items.games'),
      icon: faGamepad,
    },
    {
      desc: t('services.items.food'),
      icon: faUtensils,
    },
  ];

  return (
    <S.Services>
      <S.ServicesDescription>
        <Title
          left
          title={t('services.title')}
          labelColor={colors.green}
          dividerColor={colors.yellow}
        />

        <p>{applyBreakline(t('services.description'))}</p>
      </S.ServicesDescription>

      <S.ServicesCards>
        {servicesIcon.map((service, index) => (
          <S.ServiceCard key={index}>
            <S.ServiceCardDesc>
              <FontAwesomeIcon icon={service.icon} />
              {service.desc}
            </S.ServiceCardDesc>
          </S.ServiceCard>
        ))}
        <S.ServiceCard>
          <S.ServiceCentralIcon>
            <FontAwesomeIcon icon={faConciergeBell} />
          </S.ServiceCentralIcon>
        </S.ServiceCard>
      </S.ServicesCards>
    </S.Services>
  );
};

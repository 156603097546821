import {useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {MenuNames} from '../../../core/enums/menu-names';
import {redirect} from '../../../core/services/link-routes';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {Button} from '../../components/button';
import {City} from './city';
import {Hotel} from './hotel';
import * as S from './style';
import {Welcome} from './welcome';

export const Home = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(goToPage(MenuNames.INDEX));
  }, []);

  return (
    <S.Home>
      <S.BookingButtonWrapper>
        <div
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.CONTACT})
          }
        >
          <Button label={t('index.bookingButton')} />
        </div>
      </S.BookingButtonWrapper>

      <Welcome />

      <Hotel />

      <City />
    </S.Home>
  );
};

import {AxiosInstance} from 'axios';

import {Languages} from '../../../../enums/languages';
import {AxiosFactory} from '../../../factories/axios-factory';
import {CollectionDto} from '../../dtos/collection-dto';
import {SingleDto} from '../../dtos/single-dto';
import {CompanyDto} from './dtos/company-dto';

export class CompanyGateway {
  async find(): Promise<CollectionDto<CompanyDto>> {
    const response = await this.axiosInstance.get<SingleDto<CompanyDto>>('', {
      params: {
        locale: this.locale,
        populate: '*',
      },
    });

    return response.data.data;
  }

  constructor(
    private readonly axiosInstance: AxiosInstance,
    private readonly locale: Languages
  ) {}

  static make(locale: Languages): CompanyGateway {
    const axiosInstance = AxiosFactory.make(
      `${process.env.REACT_APP_STRAPI_BASE_URL}/company`
    );

    return new CompanyGateway(axiosInstance, locale);
  }
}

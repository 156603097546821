import styled from 'styled-components';

import {colors} from '../../../styles/global';

export const HomeHotelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1440px;
  padding: 70px 0;
  margin: 30px 0;
`;

export const HomeHotelAbstractRectangleComplete = styled.div`
  position: absolute;
  width: 40%;
  height: 80%;
  left: 0;
  top: 0;
  background: linear-gradient(
    to bottom,
    ${colors.softYellow},
    ${colors.yellow}
  );
`;

export const HomeHotelAbstractRectangleBorder = styled.div`
  position: absolute;
  width: 30%;
  height: 30%;
  right: 0;
  bottom: 0;
  background: linear-gradient(to left, ${colors.softYellow}, ${colors.yellow});
`;

export const HomeHotel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  max-width: 90%;
  border: 10px solid ${colors.green};
  background-color: ${colors.white};
  padding: 30px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (max-width: 1439px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const HomeHotelDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  min-width: 28%;
`;

export const HomeHotelPictures = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  img {
    width: 30%;
    height: auto;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

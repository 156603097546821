/* eslint-disable no-undef */

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';

import {RootState} from '../../../infrastructure/redux/store';
import * as S from './style';

export const FloatButton = () => {
  const {company} = useSelector((state: RootState) => state.company);

  const filterPhone = () => {
    if (company.whatsapp) {
      const phone = company.whatsapp
        .split('')
        .filter(c => !['(', ')', ' ', '-'].includes(c))
        .join('');

      return `55${phone}`;
    } else {
      return '';
    }
  };

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${filterPhone()}`}
      target="_blank"
    >
      <S.WppButton>
        <FontAwesomeIcon icon={faWhatsapp} />
      </S.WppButton>
    </a>
  );
};

import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {MenuNames} from '../../../../core/enums/menu-names';
import {redirect} from '../../../../core/services/link-routes';
import {Button} from '../../../components/button';
import {Title} from '../../../components/title';
import {colors, images} from '../../../styles/global';
import * as S from './style';

export const Hotel = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <S.HomeHotelWrapper>
      <S.HomeHotelAbstractRectangleComplete />
      <S.HomeHotelAbstractRectangleBorder />
      <S.HomeHotel>
        <S.HomeHotelDescription>
          <Title
            title={t('index.hotel.title')}
            labelColor={colors.green}
            dividerColor={colors.yellow}
          />

          <div
            onClick={() =>
              redirect({history, dispatch, route: MenuNames.SERVICES})
            }
          >
            <Button label={t('index.hotel.button')} />
          </div>
        </S.HomeHotelDescription>

        <S.HomeHotelPictures>
          <img
            loading="lazy"
            width={500}
            height={1139}
            alt="hotel-left"
            src={images.home.hotel.left}
          />
          <img
            loading="lazy"
            width={500}
            height={1139}
            alt="hotel-mid"
            src={images.home.hotel.mid}
          />
          <img
            loading="lazy"
            width={217}
            height={491}
            alt="hotel-right"
            src={images.home.hotel.right}
          />
        </S.HomeHotelPictures>
      </S.HomeHotel>
    </S.HomeHotelWrapper>
  );
};

import {faPagelines} from '@fortawesome/free-brands-svg-icons';
import {faCalendarCheck, faGlobe} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslate} from 'react-polyglot';

import {applyBreakline} from '../../../../core/services/text-paragraph';
import {images} from '../../../styles/global';
import * as S from './style';

export const Welcome = () => {
  const t = useTranslate();

  return (
    <S.HomeWelcome>
      <S.HomeWelcomeBanner>
        <S.HomeWelcomeAbstractRectangleComplete />
        <S.HomeWelcomeAbstractRectangleBorder />
        <img
          loading="lazy"
          width={3264}
          height={2448}
          alt="welcome-banner"
          src={images.home.welcome.banner}
        />
      </S.HomeWelcomeBanner>
      <S.HomeWelcomeDesc>
        <h1>{t('index.welcome.title')}</h1>

        <p>{applyBreakline(t('index.welcome.description'))}</p>

        <S.HomeWelcomeServices>
          <S.HomeWelcomeService>
            <FontAwesomeIcon icon={faPagelines} />
            <p>{t('index.welcome.services.pagelines')}</p>
          </S.HomeWelcomeService>
          <S.HomeWelcomeService>
            <FontAwesomeIcon icon={faCalendarCheck} />
            <p>{t('index.welcome.services.calendarCheck')}</p>
          </S.HomeWelcomeService>
          <S.HomeWelcomeService>
            <FontAwesomeIcon icon={faGlobe} />
            <p>{t('index.welcome.services.globe')}</p>
          </S.HomeWelcomeService>
        </S.HomeWelcomeServices>
      </S.HomeWelcomeDesc>
    </S.HomeWelcome>
  );
};

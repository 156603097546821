import styled from 'styled-components';

import {colors, fonts} from '../../../styles/global';

export const HomeCityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1439px) {
    flex-direction: column-reverse;
    margin-top: 30px;
    gap: 30px;
  }
`;

export const HomeCityDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 420px;
  padding: 0 30px;

  @media (max-width: 1439px) {
    padding: 0;
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;
    max-width: 90%;
  }

  p {
    text-align: justify;
    font-size: 18px;
    color: ${colors.green};
    font-family: ${fonts.verdana};
  }
`;

export const HomeCityBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 90%;
    margin: 30px 0;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
`;

export const HomeCityAbstractRectangleComplete = styled.div`
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  position: absolute;
  width: 90%;
  height: 90%;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, ${colors.green}, ${colors.softYellow});
`;

import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const TourismWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 30px 0;
`;

export const Tourism = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const TourismSection = styled.div<{left?: boolean}>`
  width: 100%;
  ${props =>
    props.left
      ? 'border-bottom-left-radius: 30px;border-top-right-radius: 30px;'
      : 'border-bottom-right-radius: 30px;border-top-left-radius: 30px;'}

  background: ${props =>
    props.left
      ? `linear-gradient(to left, ${colors.softYellow}, ${colors.yellow});`
      : `linear-gradient(to right, ${colors.darkGreen}, ${colors.green});`};
  display: flex;
  flex-direction: ${props => (props.left ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: space-between;
  padding: 60px 30px;
  gap: 30px;

  @media (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
  }

  img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 100%;
    max-width: 700px;
    ${props =>
      props.left
        ? 'border-bottom-left-radius: 30%; border-top-right-radius: 30%;'
        : 'border-bottom-right-radius: 30%; border-top-left-radius: 30%;'}
  }
`;

export const TourismDesc = styled.div<{right?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.right ? 'flex-end' : 'flex-start')};
  gap: 30px;

  @media (min-width: 1150px) {
    max-width: 425px;
  }

  @media (min-width: 1375px) {
    max-width: 625px;
  }

  h1 {
    color: ${props => (props.right ? colors.grey : colors.softGrey)};
    font-family: ${fonts.cinzel};
    font-size: 42px;
  }

  p {
    font-size: 16px;
    color: ${props => (props.right ? colors.grey : colors.softGrey)};
    font-family: ${fonts.verdana};
    text-align: ${props => props.right && 'right'};
  }
`;

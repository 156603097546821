import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faHome, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {SocialMediaDto} from '../../../core/apis/api-strapi/dtos/shared/social-media-dto';
import {MenuNames} from '../../../core/enums/menu-names';
import {filterSocialMedias} from '../../../core/services/filter-social-medias';
import {redirect} from '../../../core/services/link-routes';
import {isCurrentPage} from '../../../core/services/menu-routes';
import {RootState} from '../../../infrastructure/redux/store';
import {colors, images} from '../../styles/global';
import {Title} from '../title';
import * as S from './style';

export const Footer = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const {company} = useSelector((state: RootState) => state.company);

  const {currentPage} = useSelector((state: RootState) => state.menu);

  return (
    <S.FooterWrapper>
      <S.FooterContent>
        <S.FooterLogo
          onClick={() => redirect({history, dispatch, route: MenuNames.INDEX})}
        >
          <img
            loading="lazy"
            width={512}
            height={512}
            alt="logo-icon"
            src={images.footer.logo}
          />

          <h1>{company.name}</h1>
        </S.FooterLogo>

        <S.FooterSection>
          <Title
            left
            title={t('footer.sitemap.title')}
            labelColor={colors.yellow}
            dividerColor={colors.green}
          />
          <S.FooterMenuItems>
            <S.FooterMenuItem
              active={isCurrentPage([MenuNames.INDEX], currentPage)}
              onClick={() =>
                redirect({history, dispatch, route: MenuNames.INDEX})
              }
            >
              {t(`menu.${MenuNames.INDEX}`)}
            </S.FooterMenuItem>
            <S.FooterMenuItem
              active={isCurrentPage([MenuNames.SERVICES], currentPage)}
              onClick={() =>
                redirect({history, dispatch, route: MenuNames.SERVICES})
              }
            >
              {t(`menu.${MenuNames.SERVICES}`)}
            </S.FooterMenuItem>

            <S.FooterMenuItem
              active={isCurrentPage([MenuNames.GALLERY], currentPage)}
              onClick={() =>
                redirect({history, dispatch, route: MenuNames.GALLERY})
              }
            >
              {t(`menu.${MenuNames.GALLERY}`)}
            </S.FooterMenuItem>

            <S.FooterMenuItem
              active={isCurrentPage([MenuNames.TOURISM], currentPage)}
              onClick={() =>
                redirect({history, dispatch, route: MenuNames.TOURISM})
              }
            >
              {t(`menu.${MenuNames.TOURISM}`)}
            </S.FooterMenuItem>

            <S.FooterMenuItem
              active={isCurrentPage([MenuNames.ABOUT_CITY], currentPage)}
              onClick={() =>
                redirect({history, dispatch, route: MenuNames.ABOUT_CITY})
              }
            >
              {t(`menu.${MenuNames.ABOUT_CITY}`)}
            </S.FooterMenuItem>

            <S.FooterMenuItem
              active={isCurrentPage([MenuNames.CONTACT], currentPage)}
              onClick={() =>
                redirect({history, dispatch, route: MenuNames.CONTACT})
              }
            >
              {t(`menu.${MenuNames.CONTACT}`)}
            </S.FooterMenuItem>
          </S.FooterMenuItems>
        </S.FooterSection>

        <S.FooterContentSection>
          <S.FooterSection>
            <Title
              left
              title={t(`menu.${MenuNames.CONTACT}`)}
              labelColor={colors.yellow}
              dividerColor={colors.green}
            />
            <S.FooterSectionIconLabel>
              <FontAwesomeIcon icon={faHome} />
              {company.address}
            </S.FooterSectionIconLabel>

            <S.FooterSectionIconLabel>
              <FontAwesomeIcon icon={faWhatsapp} />
              {company.whatsapp}
            </S.FooterSectionIconLabel>

            <S.FooterSectionIconLabel>
              <FontAwesomeIcon icon={faPhone} />
              {company.mobile}
            </S.FooterSectionIconLabel>

            <S.FooterSectionIconLabel>
              <FontAwesomeIcon icon={faEnvelope} />
              {company.email}
            </S.FooterSectionIconLabel>
          </S.FooterSection>

          <S.FooterSection>
            <Title
              left
              title={t('contact.socialMedias')}
              labelColor={colors.yellow}
              dividerColor={colors.green}
            />
            <S.FooterSectionSocialMedias>
              {company.socialMedias?.map(
                (socialMedia: SocialMediaDto, index: number) => (
                  <a key={index} target="_blank" href={socialMedia.url}>
                    <FontAwesomeIcon
                      className={socialMedia.name}
                      icon={filterSocialMedias(socialMedia.name)}
                    />
                  </a>
                )
              )}
            </S.FooterSectionSocialMedias>
          </S.FooterSection>
        </S.FooterContentSection>
      </S.FooterContent>
    </S.FooterWrapper>
  );
};

import {MenuNames} from '../../../core/enums/menu-names';
import {I18n} from '../interfaces/i18n';
import {WcsLabelDto} from './wcs-label-dto';

export class PtBrRepository implements I18n<WcsLabelDto> {
  translate(): WcsLabelDto {
    return {
      languages: {
        portuguese: 'Português',
        english: 'Inglês',
      },
      menuResponsive: {
        close: 'Fechar',
      },
      menu: {
        [MenuNames.INDEX]: 'Início',
        [MenuNames.SERVICES]: 'Serviços',
        [MenuNames.GALLERY]: 'Galeria',
        [MenuNames.TOURISM]: 'Turismo',
        [MenuNames.ABOUT_CITY]: 'Conheça Moeda',
        [MenuNames.CONTACT]: 'Contato',
      },
      footer: {
        sitemap: {
          title: 'Mapa do Site',
        },
      },
      contact: {
        title: 'Contato',
        socialMedias: 'Redes Sociais',
        location: 'Localização',
        form: {
          title: 'Envie uma mensagem',
          name: {
            label: 'Nome',
            placeholder: 'Digite seu nome',
          },
          email: {
            label: 'E-mail',
            placeholder: 'Digite um e-mail para contato',
          },
          message: {
            label: 'Mensagem',
            placeholder: 'Digite sua mensagem',
          },
          submitButton: 'Enviar',
          confirmMessage: 'Obrigado por enviar sua mensagem!',
        },
      },
      index: {
        bookingButton: 'Faça sua reserva!',
        welcome: {
          title: 'Bem-vindo!',
          description:
            'O Hotel Moedense está localizado no Centro de Moeda, ideal para quem quer curtir a cidade com tranquilidade ou precisa se hospedar a trabalho com muito conforto e praticidade.\nNossa história começa em 1994 e, como o nome sugere, traz consigo a honra de ser um dos hotéis mais tradicionais e conhecidos da cidade.\nSerá uma honra te receber!',
          services: {
            pagelines: 'Natureza',
            calendarCheck: 'Comodidade',
            globe: 'Confiança',
          },
        },
        hotel: {
          title: 'Conheça nossos serviços',
          button: 'Saiba mais',
        },
        city: {
          description:
            'A história de Moeda tem início por volta do final do século XVII, com a chegada dos bandeirantes paulistas e dos portugueses em busca das riquezas minerais, durante o processo inicial da colonização da Província de Minas Gerais.',
          title: 'Conheça Moeda',
          button: 'Saiba mais',
        },
      },
      services: {
        title: 'Nossos serviços',
        description:
          'Aqui você encontra acomodações cheias de conforto, café da manhã bem mineirinho, portaria 24 horas, estacionamento, Wi-fi gratuito e sala de jogos.\nConforto e segurança em um só lugar!',
        items: {
          home: 'Portaria 24hrs',
          bath: 'Suítes completas',
          wifi: 'Wi-Fi',
          car: 'Estacionamento 24hrs',
          games: 'Salão de jogos',
          food: 'Café da manhã',
        },
      },
      tourism: {
        title: 'Pontos Turísticos',
        content: {
          sierra: {
            title: 'Serra da Moeda',
            description:
              'A Serra da Moeda é a porta de entrada da nossa cidade. O local onde a movimentação urbana dá lugar à calmaria. Venha visitar a nossa região rica em natureza e história, e prepare-se para se encantar!',
          },
          coinHouse: {
            title: 'Ruínas Casa da Moeda',
            description:
              'O local era no passado uma casa de comercialização de moedas falsas da corte portuguesa. As ruínas ficam ao lado da Igreja de São Caetano, no povoado de Moeda Velha, em Moeda (MG).',
          },
          station: {
            title: 'Estação de Moeda',
            description:
              'A linha do Paraopeba, assim chamada porque durante boa parte de sua extensão acompanha o rio do mesmo nome, foi construída em bitola larga, provavelmente para aliviar o tráfego de trens entre o Rio de Janeiro e Belo Horizonte que até sua abertura tinha de passar pela zona de mineração da Linha do Centro, até General Carneiro, onde saía a linha para a capital mineira.',
          },
        },
      },
      aboutCity: {
        title: 'Nossa história',
        description:
          'A história de Moeda tem início por volta do final do século XVII, com a chegada dos bandeirantes paulistas e dos portugueses em busca das riquezas minerais, durante o processo inicial da colonização da Província de Minas Gerais. Para fugir dos altos impostos do quinto do ouro, os portugueses construíram no meio da mata, na base da serra, um casarão denominado de “Fazenda Boa Memória”.\nA construção tornou-se a primeira fundição clandestina de moedas falsas do país. Anos mais tarde, após prisão dos falsificadores, os moradores da região identificaram o casarão como “Fazenda da Moeda”. Com isso, os moradores da região batizaram a serra que até então se chamava “Serra do Paraopeba”, com o nome de “Serra da Moeda”.\nCom a chegada dos trilhos da Estrada de Ferro Central do Brasil, o povoado começou a prosperar, recebendo novos moradores. Com o término dos trabalhos de construção da ferrovia, muitos dos trabalhadores nela empregados se fixaram em Moeda, onde foi erguida uma pequena igreja dedicada a São Caetano, povoado que veio a denominar-se “São Caetano da Moeda”.',
      },
      gallery: {
        title: 'Galeria',
      },
    };
  }
}

import {useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';

import {MenuNames} from '../../../core/enums/menu-names';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {Title} from '../../components/title';
import {colors, images} from '../../styles/global';
import * as S from './style';

export const Tourism = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(goToPage(MenuNames.TOURISM));
  }, []);

  return (
    <S.TourismWrapper>
      <S.Tourism>
        <Title
          title={t('tourism.title')}
          labelColor={colors.green}
          dividerColor={colors.yellow}
        />

        <S.TourismSection>
          <S.TourismDesc>
            <h1>{t('tourism.content.sierra.title')}</h1>
            <p>{t('tourism.content.sierra.description')}</p>
          </S.TourismDesc>

          <img
            loading="lazy"
            width={643}
            height={428}
            alt="sierra"
            src={images.tourism.sierra}
          />
        </S.TourismSection>

        <S.TourismSection left>
          <S.TourismDesc right>
            <h1>{t('tourism.content.coinHouse.title')}</h1>
            <p>{t('tourism.content.coinHouse.description')}</p>
          </S.TourismDesc>

          <img
            loading="lazy"
            width={1990}
            height={1196}
            alt="coin-house"
            src={images.tourism.coinHouse}
          />
        </S.TourismSection>

        <S.TourismSection>
          <S.TourismDesc>
            <h1>{t('tourism.content.station.title')}</h1>
            <p>{t('tourism.content.station.description')}</p>
          </S.TourismDesc>

          <img
            loading="lazy"
            width={552}
            height={414}
            alt="station"
            src={images.tourism.station}
          />
        </S.TourismSection>
      </S.Tourism>
    </S.TourismWrapper>
  );
};

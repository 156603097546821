import {AxiosInstance} from 'axios';

import {AxiosFactory} from '../../factories/axios-factory';
import {ContactUsMessageDto} from '../dtos/contact-us-message-dto';

export class ContactUsGateway {
  async sendEmail(contactUsMessage: ContactUsMessageDto): Promise<void> {
    await this.axiosInstance.post('/contact-us', contactUsMessage);
  }

  constructor(private readonly axiosInstance: AxiosInstance) {}

  static make(): ContactUsGateway {
    const axiosInstance = AxiosFactory.make(
      process.env.REACT_APP_WEB_MAIL_BASE_URL
    );

    return new ContactUsGateway(axiosInstance);
  }
}

import styled from 'styled-components';

import {colors, fonts, images} from '../../styles/global';

export const Services = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 30px 0;

  @media (max-width: 1150px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ServicesDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 30px;

  @media (min-width: 1150px) {
    max-width: 425px;
  }

  @media (min-width: 1375px) {
    max-width: 625px;
  }

  p {
    font-size: 16px;
    color: ${colors.green};
    font-family: ${fonts.verdana};
  }
`;

export const ServicesCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;

  @media (min-width: 768px) {
    width: 560px;
    height: 490px;
    margin: 0 30px;
    position: relative;
  }
`;

export const ServiceCard = styled.div`
  background: url(${images.services.yellowPolygon}) center center;
  background-size: cover;
  width: 160px;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    position: absolute;
  }

  &:nth-child(1) {
    left: 4%;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:nth-child(2) {
    left: 20%;
    top: 0;
    margin: auto;
  }

  &:nth-child(3) {
    right: 20%;
    top: 0;
    margin: auto;
  }

  &:nth-child(4) {
    right: 4%;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:nth-child(5) {
    right: 20%;
    bottom: 0;
    margin: auto;
  }

  &:nth-child(6) {
    left: 20%;
    bottom: 0;
    margin: auto;
  }

  &:nth-child(7) {
    background: url(${images.services.greenPolygon}) center center;
    background-size: cover;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const ServiceCentralIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${colors.yellow};
    width: 48px;
    font-size: 48px;
    margin: 0;
  }
`;

export const ServiceCardDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-family: ${fonts.verdana};
  font-size: 16px;
  text-align: center;
  color: ${colors.green};
  padding: 5px 30px;

  svg {
    width: 38px;
    font-size: 38px;
  }
`;

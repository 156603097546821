import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const ContactWrapper = styled.div`
  width: 90%;
  max-width: 1440px;
  padding: 30px 0;
  margin: 0 auto;
`;

export const Contact = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 30px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

export const ContactDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  max-width: 768px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContactDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContactDescriptionSectionIconLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-family: ${fonts.verdana};
  margin-top: 15px;
  color: ${colors.green};

  svg {
    color: ${colors.yellow};
    width: 20px;
    font-size: 20px;
    transition: 0.5s;
  }
`;

export const ContactDescriptionSectionIconSocialMedia = styled.div`
  margin-top: 15px;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .facebook:hover {
    color: #4267b2;
  }

  .instagram:hover {
    color: #8a3ab9;
  }

  svg {
    color: ${colors.yellow};
    width: 40px;
    font-size: 40px;
    transition: 0.5s;
  }
`;

export const ContactFormWrapper = styled.div`
  max-width: 600px;
  height: 550px;
  position: relative;
`;

export const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: linear-gradient(
    to bottom,
    ${colors.softYellow},
    ${colors.yellow}
  );

  border-bottom-left-radius: 30%;
  border-top-right-radius: 30%;
  position: relative;
  z-index: 2;

  h1 {
    padding: 0 30px;
    font-family: ${fonts.cinzel};
    font-size: 36px;
    text-align: center;
    color: ${colors.green};

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ContactFormAbstractRectangleBorder = styled.div`
  position: absolute;
  width: 95%;
  height: 90%;
  right: 10px;
  top: 10px;
  border: 15px solid ${colors.green};
  z-index: 1;
`;

export const ContactUsField = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  min-height: 85px;
  width: 100%;
  margin: 10px 0;

  label {
    font-family: ${fonts.verdana};
    font-size: 16px;
    line-height: 18px;
    margin: 5px 0;
    color: ${colors.green};
  }

  input,
  textarea {
    font-family: ${fonts.verdana};
    font-size: 13px;
    line-height: 18px;
    border: 1px solid ${colors.softGrey};
    border-radius: 5px;
    padding: 16px 13px;
    font-size: 14px;

    &:focus {
      border: 1px solid ${colors.grey};
      color: ${colors.grey};
    }
  }

  textarea {
    min-height: 100px;
    resize: none;
  }
`;

export const Button = styled.button`
  background: ${colors.green};
  padding: 8px 12px;

  width: 120px;
  height: 36px;
  color: ${colors.white};
  border-radius: 5px;
  border: none;
  font-family: ${fonts.verdana};
  font-size: 16px;
  line-height: 18px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${colors.darkGreen};
  }

  &:disabled {
    background: ${colors.darkGreen};
  }

  svg {
    width: 20px;
    font-size: 20px;
    color: ${colors.white};
    animation: spin infinite 5s linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const ContactLocation = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  iframe {
    border-top: 6px solid ${colors.yellow};
  }
`;

import styled from 'styled-components';

import {colors, fonts} from '../../../styles/global';

export const HomeWelcome = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 70px;
  margin-bottom: 100px;

  @media (max-width: 1439px) {
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;
  }
`;

export const HomeWelcomeBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 700px;

  img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 90%;
    margin: 30px 0;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 30%;
  }
`;

export const HomeWelcomeAbstractRectangleComplete = styled.div`
  position: absolute;
  width: 90%;
  height: 80%;
  left: 0;
  bottom: 0;
  background: linear-gradient(to top, ${colors.softYellow}, ${colors.yellow});
`;

export const HomeWelcomeAbstractRectangleBorder = styled.div`
  position: absolute;
  width: 90%;
  height: 80%;
  right: 0;
  top: 0;
  border: 15px solid ${colors.yellow};
`;

export const HomeWelcomeDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  h1 {
    text-align: center;
    color: ${colors.green};
    margin: 5px 0;
    font-family: ${fonts.cinzel};
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 97px;

    @media (min-width: 426px) {
      font-size: 72px;
    }
  }

  p {
    text-align: center;
    font-size: 18px;
    color: ${colors.green};
    font-family: ${fonts.verdana};
  }
`;

export const HomeWelcomeServices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const HomeWelcomeService = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  svg {
    width: 26px;
    font-size: 26px;
    color: ${colors.softYellow};
  }

  p {
    font-size: 18px;
    color: ${colors.green};
    font-family: Verdana;
  }
`;

import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const LanguageSelect = styled.div`
  font-family: ${fonts.verdana};
  cursor: pointer;
`;

export const LanguageDefaultOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: ${colors.green};
  margin-top: -24px;
  cursor: pointer;

  svg {
    font-size: 15px;
    height: 15px;
  }
`;

export const LanguageOption = styled.div`
  color: ${colors.green};
`;

import React, {useEffect, useState} from 'react';
import Gallery from 'react-photo-gallery';
import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import {MenuNames} from '../../../core/enums/menu-names';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {Title} from '../../components/title';
import {colors, images} from '../../styles/global';
import * as S from './style';

export const GalleryPage = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(goToPage(MenuNames.GALLERY));
  }, []);

  const photos = [
    {
      src: images.gallery.img7,
      thumbnail: {
        width: 3,
        height: 4,
      },
    },
    {
      src: images.gallery.img8,
      thumbnail: {
        width: 7,
        height: 3,
      },
    },
    {
      src: images.gallery.img10,
      thumbnail: {
        width: 4,
        height: 3,
      },
    },
    {
      src: images.gallery.img12,
      thumbnail: {
        width: 3,
        height: 4,
      },
    },
    {
      src: images.gallery.img14,
      thumbnail: {
        width: 4,
        height: 3,
      },
    },
    {
      src: images.gallery.img15,
      thumbnail: {
        width: 3,
        height: 4,
      },
    },
    {
      src: images.gallery.img18,
      thumbnail: {
        width: 3,
        height: 4,
      },
    },
    {
      src: images.gallery.img19,
      thumbnail: {
        width: 3,
        height: 4,
      },
    },
    {
      src: images.gallery.img21,
      thumbnail: {
        width: 3,
        height: 4,
      },
    },
    {
      src: images.gallery.img26,
      thumbnail: {
        width: 4,
        height: 3,
      },
    },
  ];

  const [index, setIndex] = useState(-1);

  return (
    <S.GalleryWrapper>
      <S.Gallery>
        <Title
          title={t('gallery.title')}
          labelColor={colors.green}
          dividerColor={colors.yellow}
        />

        <Gallery
          photos={photos.map(photo => ({
            src: photo.src,
            width: photo.thumbnail.width,
            height: photo.thumbnail.height,
          }))}
          onClick={(_event, {index}) => setIndex(index)}
        />

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={photos}
          plugins={[Zoom]}
        />
      </S.Gallery>
    </S.GalleryWrapper>
  );
};

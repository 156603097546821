import styled from 'styled-components';

import {colors} from '../../styles/global';

export const Loader = styled.div`
  animation: 0.5s ease-in-out fadein;
  background-color: ${colors.white};

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

import {useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {MenuNames} from '../../../../core/enums/menu-names';
import {redirect} from '../../../../core/services/link-routes';
import {applyBreakline} from '../../../../core/services/text-paragraph';
import {Button} from '../../../components/button';
import {Title} from '../../../components/title';
import {colors, images} from '../../../styles/global';
import * as S from './style';

export const City = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <S.HomeCityWrapper>
      <S.HomeCityDesc>
        <Title
          title={t('index.city.title')}
          labelColor={colors.green}
          dividerColor={colors.yellow}
        />

        <p>{applyBreakline(t('index.city.description'))}</p>

        <div
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.ABOUT_CITY})
          }
        >
          <Button label={t('index.city.button')} />
        </div>
      </S.HomeCityDesc>
      <S.HomeCityBanner>
        <S.HomeCityAbstractRectangleComplete />

        <img
          loading="lazy"
          width={1440}
          height={780}
          alt="home-city"
          src={images.home.city.banner}
        />
      </S.HomeCityBanner>
    </S.HomeCityWrapper>
  );
};

export type ContactUsState = {
  sending: boolean;
  fields: {
    name: string;
    email: string;
    message: string;
  };
};

export const InitialContactUsState = {
  sending: false,
  fields: {
    name: '',
    email: '',
    message: '',
  },
};

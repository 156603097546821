import {faBars, faPhone, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Languages} from '../../../core/enums/languages';
import {MenuNames} from '../../../core/enums/menu-names';
import {redirect} from '../../../core/services/link-routes';
import {isCurrentPage} from '../../../core/services/menu-routes';
import {changeLanguage} from '../../../infrastructure/redux/slices/locale-slice';
import {toggleMenu} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import {colors, images} from '../../styles/global';
import {LanguageSelect} from '../language-select';
import * as S from './style';

export const Menu = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const {currentPage, open} = useSelector((state: RootState) => state.menu);

  const {company} = useSelector((state: RootState) => state.company);

  const options = [
    {
      value: Languages.PT_BR,
      label: t('languages.portuguese'),
    },
    {
      value: Languages.EN_US,
      label: t('languages.english'),
    },
  ];

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  const renderMenuItems = () => {
    return (
      <>
        <S.MenuLink
          onClick={() => redirect({history, dispatch, route: MenuNames.INDEX})}
          active={isCurrentPage([MenuNames.INDEX], currentPage)}
        >
          {t(`menu.${MenuNames.INDEX}`)}
        </S.MenuLink>

        <S.MenuLink
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.SERVICES})
          }
          active={isCurrentPage([MenuNames.SERVICES], currentPage)}
        >
          {t(`menu.${MenuNames.SERVICES}`)}
        </S.MenuLink>

        <S.MenuLink
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.GALLERY})
          }
          active={isCurrentPage([MenuNames.GALLERY], currentPage)}
        >
          {t(`menu.${MenuNames.GALLERY}`)}
        </S.MenuLink>

        <S.MenuLink
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.TOURISM})
          }
          active={isCurrentPage([MenuNames.TOURISM], currentPage)}
        >
          {t(`menu.${MenuNames.TOURISM}`)}
        </S.MenuLink>

        <S.MenuLink
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.ABOUT_CITY})
          }
          active={isCurrentPage([MenuNames.ABOUT_CITY], currentPage)}
        >
          {t(`menu.${MenuNames.ABOUT_CITY}`)}
        </S.MenuLink>

        <S.MenuLink
          onClick={() =>
            redirect({history, dispatch, route: MenuNames.CONTACT})
          }
          active={isCurrentPage([MenuNames.CONTACT], currentPage)}
        >
          {t(`menu.${MenuNames.CONTACT}`)}
        </S.MenuLink>
      </>
    );
  };

  return (
    <S.NavWrapper>
      <S.MenuWrapper>
        <S.MenuItemsWrapperResponsive>
          <LanguageSelect
            options={options}
            currentOption={options.filter(option => option.value === locale)[0]}
            changeOption={language =>
              dispatch(changeLanguage(language.value as Languages))
            }
          />

          <S.MenuItemsResponsiveOpenIcon onClick={() => dispatch(toggleMenu())}>
            <FontAwesomeIcon color={colors.green} icon={faBars} />
          </S.MenuItemsResponsiveOpenIcon>
        </S.MenuItemsWrapperResponsive>

        <S.Logo>
          <div
            onClick={() =>
              redirect({history, dispatch, route: MenuNames.INDEX})
            }
            className="icon"
          >
            <img
              loading="lazy"
              width={1096}
              height={561}
              alt="menu-logo"
              src={images.menu.logo}
            />
          </div>
        </S.Logo>

        <S.Menu>
          <S.MenuHeader>
            <S.MenuHeaderPhone>
              <FontAwesomeIcon icon={faPhone} />
              <p>{company.mobile}</p>
            </S.MenuHeaderPhone>

            <div className="language-select">
              <LanguageSelect
                options={options}
                currentOption={
                  options.filter(option => option.value === locale)[0]
                }
                changeOption={language =>
                  dispatch(changeLanguage(language.value as Languages))
                }
              />
            </div>
          </S.MenuHeader>

          <S.MenuItems>{renderMenuItems()}</S.MenuItems>
        </S.Menu>
      </S.MenuWrapper>

      <S.MenuItemsResponsive open={open}>
        <S.MenuItemsResponsiveCloseIcon onClick={() => dispatch(toggleMenu())}>
          {t('menuResponsive.close')}
          <FontAwesomeIcon color={colors.green} icon={faXmark} />
        </S.MenuItemsResponsiveCloseIcon>

        <S.MenuItemsResponsiveContent>
          {renderMenuItems()}
        </S.MenuItemsResponsiveContent>
      </S.MenuItemsResponsive>
    </S.NavWrapper>
  );
};

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faHome,
  faPhone,
  faRotate,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';

import {ContactUsGateway} from '../../../core/apis/api-frm/gateways/contact-us-gateway';
import {SocialMediaDto} from '../../../core/apis/api-strapi/dtos/shared/social-media-dto';
import {MenuNames} from '../../../core/enums/menu-names';
import {filterSocialMedias} from '../../../core/services/filter-social-medias';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import {Title} from '../../components/title';
import {colors} from '../../styles/global';
import {InitialContactUsState} from './state';
import * as S from './style';

export const Contact = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [sending, setSending] = useState(InitialContactUsState.sending);

  const [fields, setFields] = useState(InitialContactUsState.fields);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(goToPage(MenuNames.CONTACT));
  }, []);

  const handleChange = (
    field: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();

    setFields({
      ...fields,
      [field]: e.target.value,
    });
  };

  const submit = async (): Promise<void> => {
    try {
      await ContactUsGateway.make().sendEmail({
        name: fields.name,
        fromEmail: fields.email,
        message: fields.message,
      });

      window.confirm(t('contact.form.confirmMessage'));

      setFields(InitialContactUsState.fields);
    } catch (error) {
      console.error(error);
    } finally {
      setSending(false);
    }
  };

  const submitEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSending(true);

    await submit();
  };

  const {company} = useSelector((state: RootState) => state.company);

  return (
    <>
      <S.ContactWrapper>
        <S.Contact>
          <S.ContactDescription>
            <S.ContactDescriptionSection>
              <Title
                left
                title={t('contact.title')}
                labelColor={colors.green}
                dividerColor={colors.yellow}
              />
              <S.ContactDescriptionSectionIconLabel>
                <FontAwesomeIcon icon={faHome} />
                {company.address}
              </S.ContactDescriptionSectionIconLabel>

              <S.ContactDescriptionSectionIconLabel>
                <FontAwesomeIcon icon={faWhatsapp} />
                {company.whatsapp}
              </S.ContactDescriptionSectionIconLabel>

              <S.ContactDescriptionSectionIconLabel>
                <FontAwesomeIcon icon={faPhone} />
                {company.mobile}
              </S.ContactDescriptionSectionIconLabel>
              <S.ContactDescriptionSectionIconLabel>
                <FontAwesomeIcon icon={faEnvelope} />
                {company.email}
              </S.ContactDescriptionSectionIconLabel>
            </S.ContactDescriptionSection>
            <S.ContactDescriptionSection>
              <Title
                left
                title={t('contact.socialMedias')}
                labelColor={colors.green}
                dividerColor={colors.yellow}
              />
              <S.ContactDescriptionSectionIconSocialMedia>
                {company.socialMedias?.map(
                  (socialMedia: SocialMediaDto, index: number) => (
                    <a key={index} target="_blank" href={socialMedia.url}>
                      <FontAwesomeIcon
                        className={socialMedia.name}
                        icon={filterSocialMedias(socialMedia.name)}
                      />
                    </a>
                  )
                )}
              </S.ContactDescriptionSectionIconSocialMedia>
            </S.ContactDescriptionSection>
          </S.ContactDescription>
          <S.ContactFormWrapper>
            <S.ContactFormAbstractRectangleBorder />

            <S.ContactForm>
              <h1>{t('contact.form.title')}</h1>
              <form onSubmit={e => submitEmail(e)}>
                <S.ContactUsField>
                  <label>{t('contact.form.name.label')}:</label>

                  <input
                    value={fields.name}
                    onChange={e => handleChange('name', e)}
                    required
                    tabIndex={1}
                    type="text"
                    placeholder={t('contact.form.name.placeholder')}
                  />
                </S.ContactUsField>

                <S.ContactUsField>
                  <label>{t('contact.form.email.label')}:</label>

                  <input
                    value={fields.email}
                    onChange={e => handleChange('email', e)}
                    required
                    tabIndex={2}
                    type="email"
                    placeholder={t('contact.form.email.placeholder')}
                  />
                </S.ContactUsField>

                <S.ContactUsField>
                  <label>{t('contact.form.message.label')}:</label>

                  <textarea
                    value={fields.message}
                    onChange={e => handleChange('message', e)}
                    required
                    tabIndex={3}
                    placeholder={t('contact.form.message.placeholder')}
                  />
                </S.ContactUsField>

                <S.Button disabled={sending} type="submit">
                  {sending ? (
                    <FontAwesomeIcon icon={faRotate} />
                  ) : (
                    t('contact.form.submitButton')
                  )}
                </S.Button>
              </form>
            </S.ContactForm>
          </S.ContactFormWrapper>
        </S.Contact>
      </S.ContactWrapper>

      <S.ContactLocation>
        <Title
          title={t('contact.location')}
          labelColor={colors.green}
          dividerColor={colors.yellow}
        />

        <iframe
          width="100%"
          height="400"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Avenida%20Prateado,%20200%20Centro%20-%20Moeda+(Hotel%20Moedense)&amp;t=h&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </S.ContactLocation>
    </>
  );
};

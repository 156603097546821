import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const AboutCity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 30px 0;

  @media (max-width: 1150px) {
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }
`;

export const AboutCityDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 30px;

  @media (min-width: 1150px) {
    max-width: 425px;
  }

  @media (min-width: 1375px) {
    max-width: 625px;
  }

  p {
    font-size: 16px;
    color: ${colors.green};
    font-family: ${fonts.verdana};
  }
`;

export const AboutCityCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  border-radius: 30%;
`;

export const AboutCityLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 725px) {
    width: 100%;
  }
`;

export const AboutCityLeftSquare = styled.div<{image: string}>`
  height: 50%;
  background: url(${props => props.image}) no-repeat center center;
  background-size: cover;
  border-bottom-left-radius: 30%;
  border-top-left-radius: 0;
  border: 3px solid ${colors.yellow};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const AboutCityRight = styled.div`
  height: 100%;

  img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 3px solid ${colors.yellow};
    border-top-right-radius: 30%;
  }
`;

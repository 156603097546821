import {MenuNames} from '../../../core/enums/menu-names';
import {I18n} from '../interfaces/i18n';
import {WcsLabelDto} from './wcs-label-dto';

export class EnUsRepository implements I18n<WcsLabelDto> {
  translate(): WcsLabelDto {
    return {
      languages: {
        portuguese: 'Portuguese',
        english: 'English',
      },
      menuResponsive: {
        close: 'Close',
      },
      menu: {
        [MenuNames.INDEX]: 'Home',
        [MenuNames.SERVICES]: 'Services',
        [MenuNames.GALLERY]: 'Gallery',
        [MenuNames.TOURISM]: 'Tourism',
        [MenuNames.ABOUT_CITY]: 'About City',
        [MenuNames.CONTACT]: 'Contact',
      },
      footer: {
        sitemap: {
          title: 'Site Map',
        },
      },
      contact: {
        title: 'Contact',
        socialMedias: 'Social Medias',
        location: 'Location',
        form: {
          title: 'Send a message',
          name: {
            label: 'Name',
            placeholder: 'Enter your name',
          },
          email: {
            label: 'E-mail',
            placeholder: 'Enter a contact email',
          },
          message: {
            label: 'Message',
            placeholder: 'Enter your message',
          },
          submitButton: 'Send',
          confirmMessage: 'Thank you for sending a message!',
        },
      },
      index: {
        bookingButton: 'Make a reservation!',
        welcome: {
          title: 'Welcome!',
          description:
            'The Hotel Moedense is located in the Moeda Center, ideal for those who want to enjoy the city with tranquility or need to stay for business with great comfort and practicality.\nOur history begins in 1994 and, as the name suggests, brings with it the honor of being one of the most traditional and well-known hotels in the city.\nIt will be an honor to receive you!',
          services: {
            pagelines: 'Nature',
            calendarCheck: 'Convenience',
            globe: 'Confidence',
          },
        },
        hotel: {
          title: 'Our services',
          button: 'Learn more',
        },
        city: {
          title: 'Discover The City',
          description:
            'The history of Moeda begins around the end of the 17th century, with the arrival of the São Paulo and Portuguese pioneers in search of mineral wealth, during the initial colonisation process of the Province of Minas Gerais.',
          button: 'Learn more',
        },
      },
      services: {
        title: 'Avaliable Services',
        description:
          'Here you will find accommodations full of comfort, a very Minas Gerais breakfast, 24-hour concierge, parking, free Wi-Fi and games room.\nComfort and safety in one place!',
        items: {
          home: 'Lobby 24hrs',
          bath: 'Complete suites',
          wifi: 'Wi-Fi',
          car: 'Parking 24hrs',
          games: 'Games room',
          food: 'Breakfast',
        },
      },
      tourism: {
        title: 'Attractions',
        content: {
          sierra: {
            title: 'Moeda Sierra',
            description:
              'Serra da Moeda is the gateway to our city. The place where urban movement gives way to calm. Come and visit our region rich in nature and history, and get ready to be enchanted!',
          },
          coinHouse: {
            title: 'Ruins of Casa da Moeda',
            description:
              'The place was in the past a trading house for counterfeit coins from the Portuguese court. The ruins are next to the Church of São Caetano, in the village of Moeda Velha, in Moeda (MG).',
          },
          station: {
            title: 'Moeda Station',
            description:
              'The Paraopeba line, so called because for a large part of its length it follows the river of the same name, was built in wide gauge, probably to relieve the traffic of trains between Rio de Janeiro and Belo Horizonte that until its opening had to pass through mining area of Linha do Centro, until General Carneiro, where the line left for the mining capital.',
          },
        },
      },
      aboutCity: {
        title: 'Our Story',
        description:
          'The history of Moeda begins around the end of the 17th century, with the arrival of the São Paulo and Portuguese pioneers in search of mineral wealth, during the initial colonization process of the Province of Minas Gerais. To escape the high taxes of the fifth of gold, the Portuguese built in the middle of the forest, at the base of the mountain, a big house called "Fazenda Boa Memória".\nConstruction became the country\'s first clandestine counterfeit smelter. Years later, after arresting the counterfeiters, the region\'s residents identified the mansion as “Fazenda da Moeda”.\nWith the arrival of the tracks of the Central Railway of Brazil, the village began to prosper, receiving new residents. With the completion of the railway construction work, many of the workers employed in it settled in Moeda, where a small church was built dedicated to São Caetano, a village that came to be called “São Caetano da Moeda”.',
      },
      gallery: {
        title: 'Gallery',
      },
    };
  }
}

import styled from 'styled-components';

import {fonts} from '../../styles/global';

export const Title = styled.div<{
  labelColor: string;
  left?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.left ? 'flex-start' : 'center')};
  min-height: 61px;

  h1 {
    padding: 0;
    font-family: ${fonts.cinzel};
    font-size: 36px;
    text-align: center;
    color: ${props => props.labelColor};

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

export const TitleDivider = styled.div<{
  dividerColor: string;
}>`
  width: 90px;
  height: 6px;
  background-color: ${props => props.dividerColor};
`;
